.filter_box {
    height: "750px";
    max-height: "800px";
    /* maxW:"70%" */
    overflow: scroll;
    margin-top: "30px";
    border-left: 1px solid rgb(233, 230, 230);
   
}
::-webkit-scrollbar {
    width: 5px;
  }

.checkbox {
    border-color: rgba(0, 0, 0, 0.69);
}

.filter_header{
    font: "DM Sans";
    font-size: 17px;
    font-weight: bold;
}
.filter_text{
    font: "DM Sans";
    font-size: 14px;
}

.my_accordion{
    border-top: none;
    border-bottom: none;
    /* width: "50px"; */
}
/* Hide scrollbar for Chrome, Safari and Opera
.filter_box::-webkit-scrollbar {
    display: none;
} */

