.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    overflow-y: auto;
    text-align: center;
  }
  .mini-row-20{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    overflow-x: auto;
    text-align: center;
  }

  .mini-row-50{
    padding-top: 5px;
    display: flex;
    /* justify-content: center; */
    align-content: center;
    height: 50px;
    overflow-y: auto;
    text-align: center;

  }
  .mini-row-65{
    display: flex;
    /* justify-content: center; */
    align-content: center;
    height: 65px;
    overflow-y: auto;

  }
  
  .centered-obj {
    text-align: center;
  }
